/* .MuiToolbar-gutters {
  background-color: #3396d3 !important;
}
logo__right
.MuiTablePagination-toolbar {
  background-color: #fff !important;
} */
/* MuiToolbar-root MuiToolbar-regular MuiToolbar-gutters */
/* MuiToolbar-root MuiToolbar-regular MuiTablePagination-toolbar MuiToolbar-gutters */

.hide {
  transform: translateY(-140px);
}
.logo__right {
  position: fixed;
  right: 10px;
  margin-right: 10px;
  top: 30px;
  display: inline;
  /* box-shadow: 0px 3px 6px #0000002E; */
}
/* Landscape phones and down */
@media (max-width: 480px) {
  .logo__right {
    display: none !important;
  }
  .topbar__para {
    font-size: 12px !important;
  }
  .topbar__title {
    font-size: 16px !important;
  }
}
.makeStyles-navbar-18 {
  background-color: white !important;
}
.MuiTableCell-sizeSmall:last-child {
  color: #b10932;
}
.MuiTableCell-head {
  font-weight: bold;
  color: #b10932;
}
.MuiPaginationItem-page.Mui-selected {
  background-color: #b10932 !important;
}
/* .MuiGrid-grid-xs-12{
  min-width:100%;
} */

.viewWrapper {
  /* display:flex;
  justify-content: space-evenly; */
  /* flex-wrap:wrap; */
}
.viewWrapper li {
  list-style-type: none;
}
.left {
  background-color: white !important;
  color: #b10932 !important;
}
.right {
  color: white;
  background-color: #b10932 !important;
}
.main {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-grow: row-wrap;
}
.top {
  width: 50%;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}
.MuiToolbar-regular {
  background-color: white !important;
}

.makeStyles-title-40 {
  color: #b10932 !important;
}

.makeStyles-icon-39 {
  color: #b10932 !important;
}
.makeStyles-icon-34 p {
  color: #b10932 !important;
}
.makeStyles-navbar-12 {
  background-color: white !important;
}
.makeStyles-title-36 {
  color: #b10932 !important;
}
.makeStyles-icon-35 {
  color: #b10932 !important;
}
/* .MuiButton-label p,
svg,
span {
  color: #b10932 !important;
} */
.makeStyles-navbar-18 {
  background-color: white !important;
}
.makeStyles-navbar-13 {
  background-color: white !important;
}
/* .MuiButton-label p,svg{
  color:#B10932 !important; 
} */

.top2 {
  padding-top: 20px;
}
