.topBarWrapper,
.topBarWrapper1 {
  height: 3.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  /* background-color: orange; */
  background-color: #f2e0e5;
  /* top: 10px; */
  /* margin-top: 13px; */
  margin: 12px 12px 25px 12px;
  border-radius: 5px;
}
.tobBarBackBtn svg {
  color: #b10932 !important;
}
.topBarWrapper1 {
  justify-content: flex-start;
}
.topBarWrapper h2 {
  font: normal normal normal 16px/20px "Avenir W01", Avenir, Arial, sans-serif;
  color: #b10932;
  opacity: 1;
}

.tobBarBackBtn {
  margin-top: 0.3rem;
  cursor: pointer;
  padding-right: 0.6rem;
}

.topBarWrapper1 a {
  margin-top: 5px;
  padding-right: 1rem;
  color: var(--colorPrimary);
}

.topBarRightBtnsWrapper {
  display: flex;
}

.topBarRightBtnsWrapper .csv {
  margin-right: 0.5rem;
  cursor: pointer;
  background-color: var(--colorPrimary);
  border-radius: 0.3rem;
  border: none;
  outline: none;
  padding: 0.5rem;
  color: #f1f1f1;
  transition: transform 0.1s ease-in-out;
  font-size: 0.9rem;
  font-weight: 700;
  min-width: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.topBarRightBtnsWrapper .csv:active {
  transform: scale(0.96);
}

.topBarInner {
  display: flex;
  align-items: center;
}

.topBarInner > a {
  margin-right: 1rem;
  margin-top: 0.5em;
}

.csvBtn {
  margin-right: 0.5rem;
  outline: none;
  border-radius: 0.3rem;
  /* color: var(--colorPrimary);
  border: 2px solid var(--colorPrimary); */
  color: #b10932;
  border: 2px solid #b10932;
  background-color: white;
  /* margin-top: 10px; */

  cursor: pointer;
  min-width: 8rem;
  font-size: 0.9rem;
  font-weight: 700;
  padding: 0.5rem;
}
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
  .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  padding-right: 65px;
  background-color: #ffffff;
}
.MuiInputBase-input:invalid {
  background-color: #ffffff !important;
}
.MuiNativeSelect-select .MuiNativeSelect-select {
  font-size: 7px;
}
/* .MuiCard-root:not(:first-child) {
  color: #b10932;
} */
.left {
  float: left;
  background-color: aquamarine;
  width: 150px;
}
.right {
  float: right;
  background-color: aqua;
  width: 150px;
}
.mainn {
  width: 300px;
}
.MuiInput-input {
  font-size: 10px;
}
/* import {
  Button,
  ListItem,
  makeStyles
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { RiArrowDropDownLine } from 'react-icons/ri';
import Fade from 'react-reveal/Fade';
import { NavLink as RouterLink } from 'react-router-dom';
import './
';
import styles from './NavItem.module.css';
// import { loadData } from 'src/utils/sessionStorage'; */

/* const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  }, */
/* button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%',
  },
  icon: {
    marginRight: theme.spacing(1),
    color: 'white',
  },
  title: {
    marginRight: 'auto',
    color: 'white',
    fontSize: '16px',
  },
  active: {
    color: theme.palette.primary.main,

    '& $title': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& $icon': {
      color: 'white',
    },
  },
  formControl: {
    minWidth: 100,
  },
  drop: {
    display: 'flex',
    alignItems: 'center',
  },
}));
const NavItem = ({ className, href, icon: Icon, title, ...rest }) => {
  const classes = useStyles();
  const [user, setUser] = React.useState(false);
  const userOpen = () => {
    setUser(!user);
  };

  return (
    <>
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        {...rest}
      >
        <Button
          activeClassName={classes.active}
          className={classes.button}
          component={RouterLink}
          to={href}
        >
          {Icon && <Icon className={classes.icon} size="20" />}
          <span className={classes.title}>
            {title === 'Users' || title === 'Master' || title === 'Forms' ? (
              <div>
                <div className={styles.flex} onClick={userOpen}>
                  <p>{title}</p>
                  <RiArrowDropDownLine
                    style={{ color: '#B10932' }}
                    className={styles.dropdown}
                  />
                </div>
              </div>
            ) : (
              title
            )}
          </span>
        </Button>
      </ListItem>
      {user && title === 'Users' && (
        <div>
          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={RouterLink}
            to="/app/usercreate"
          >
            <Fade>
              <div className={styles.dropdownBox + ' ' + classes.drop}>
                <Icon className={classes.icon} size="20" />
                <p className={classes.title}>Create User</p>
              </div>
            </Fade>
          </Button>

          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={RouterLink}
            to="/app/userlist"
          >
            <Fade>
              <div className={styles.dropdownBox + ' ' + classes.drop}>
                <Icon className={classes.icon} size="20" />
                <p className={classes.title}>User List</p>
              </div>
            </Fade>
          </Button>
        </div>
      )}
      {user && title === 'Master' && (
        <div>
          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={RouterLink}
            to="/app/state/list"
          >
            <Fade>
              <div className={styles.dropdownBox + ' ' + classes.drop}>
                <p className={classes.title}>State</p>
              </div>
            </Fade>
          </Button>
          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={RouterLink}
            to="/app/district/list"
          >
            <Fade>
              <div className={styles.dropdownBox + ' ' + classes.drop}>
                <p className={classes.title}>District</p>
              </div>
            </Fade>
          </Button>
          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={RouterLink}
            to="/app/tea_estate/list"
          >
            <Fade>
              <div className={styles.dropdownBox + ' ' + classes.drop}>
                <p className={classes.title}>Tea estate</p>
              </div>
            </Fade>
          </Button>
          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={RouterLink}
            to="/app/division/list"
          >
            <Fade>
              <div className={styles.dropdownBox + ' ' + classes.drop}>
                <p className={classes.title}>Division</p>
              </div>
            </Fade>
          </Button>
        </div>
      )}
    </>
  );
};


 */

img.top1 {
  width: 137px;
  height: 50px;
}
.top2 {
  width: 86px;
  height: 45px;
  padding-top: 5px !important;
}
/* logo */
.makeStyles-title-21 {
  color: #676767 !important;
}

/*  */
.MuiButton-containedPrimary {
  color: #fff;
  background-color: #19191a;
}
.makeStyles-title-27,
.makeStyles-title-21,
.makeStyles-title-26,
.makeStyles-item-23,
.MuiFormControl-root.MuiFormControl-fullWidth {
  border-radius: 5px;
  font: normal normal 400 16px/22px "Avenir W01", Avenir, Arial, sans-serif;
}

.MuiButton-label p,
svg {
  /* color: #676767 !important; */
}

.MuiFormControl-root.MuiFormControl-fullWidth > label {
  margin-left: 5px;

  letter-spacing: 0.64px;

  font: normal normal 400 16px/22px "Avenir W01", Avenir, Arial, sans-serif;
}
/* .MuiGrid-spacing-xs-3 > .MuiGrid-item,
.MuiBox-root-19 {
  background-color: #f6f6f6;
} */
.MuiCard-root {
  margin: 10px;
  border-radius: 5px;
  background-color: #f6f6f6 !important;
  border: 1px solid #f6f6f6;
}
