@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=a594d1e3-da5f-415d-ae9f-971e9797b9d5");

body,
html {
  font-family: "Avenir W01", Avenir, Arial, sans-serif;
  font-size: 90.5%;
  /* 62.5% of 16px = 10px */
}

body {
  background-color: #f5f5f563 !important;
}

.layoutPage {
  background-color: #f5f5f563;
}

.MuiInputBase-input {
  height: 0.5rem;
}

.MuiSelect-select.MuiSelect-select {
  padding-right: 24px;
  background-color: transparent !important;
}

.textselect {
  height: auto !important;
}

button {
  text-transform: unset !important;
}

.MuiFormControl-fullWidth {
  width: 100%;
  background-color: #fff;
}

.MuiDivider-root {
  background-color: transparent !important;
}

.MuiList-root {
  margin-top: 0px !important;
}

.MuiButton-root {
  background-color: #3396d3;
}

.hero-banner {
  z-index: 0;
}

.makeStyles-desktopDrawer-11 {
  width: 255px !important;
  border-right: none !important;
}

.MuiDropzoneArea-icon {
  color: 'red';
  height: '40px';

}

/* .pd-user-create {
  padding: 0% !important;
} */

.MuiOutlinedInput-marginDense {
  padding: 0px !important;
}

.MuiButton-root {
  background-color: #3396d3;
}

.hero-banner {
  z-index: 0;
}

.inputRounded .MuiOutlinedInput-root {
  /* border-radius: 10px; */
  padding-top: 4px;
  height: 35px;
  min-height: 35px;
  border-radius: 2px !important;
}

.MuiInputAdornment-root {
  /* border-radius: 10px; */
  height: 35px;
  min-height: 35px;
}

.MuiInput-root {
  /* border-radius: 10px; */
  height: 35px;
  min-height: 35px;
}

.MuiAutocomplete-root {
  /* border-radius: 10px; */
  /* height: 35px; */
  min-height: 35px;
}

.MuiFormLabel-root {
  color: "red"
}

.MuiOutlinedInput-root {
  /* border-radius: 10px; */
  padding-top: 4px;
  background-color: #ffffff;
  min-height: 35px;
}

/* .MuiList-root .MuiList-padding .css-h4y409-MuiList-root {
  z-index: 9999 !important;
} */
.autoComplete .MuiAutocomplete-inputRoot {
  /* border-radius: 10px; */
  /* background-color: #3396d3; */
  padding-top: 4px;
  min-height: 35px;
}

.autoComplete .MuiAutocomplete-input {
  /* width: 10px; */
  padding-top: 4px;
  /* border-radius: 10px; */
  height: 12px;
  /* background-color: #a0cb3a; */
}

.MuiAutocomplete-popupIndicator .MuiAutocomplete-popupIndicatorOpen .MuiAutocomplete-popper {
  z-index: 1100 !important;
}

.MuiAutocomplete-popper {
  z-index: 1100 !important;
}

.MuiAutocomplete-popper {
  z-index: 1100 !important;
}

/* .row > th {
  width: 10px !important;
  padding: 0px 26px !important;
} */
.margin-scale {
  margin-top: 25px;
}

.image-zoom {
  background-color: #6660 !important;
  transform: scale(0.7);
}

.image-zoom:hover {
  transform: scale(3.5);
}

.progessBar {
  display: grid;
  place-items: center;
  height: 100vh;
}

.progessBar>.MuiCircularProgress-indeterminate {
  margin-bottom: 200px;
}

.commonLoader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.zoom {
  transition: transform 0.2s !important;
  /* Animation */
  width: 200px !important;
  height: 200px !important;
  border: none !important;
}

/* .chartjs-render-monitor {
  width: 40vw;
  margin: auto;
  height: 30vh;
  height: 295px !important;
} */
#root {
  background-color: white !important;
}

.makeStyles-active-28 {
  color: #3396d3;
}

.makeStyles-active-28 .makeStyles-icon-26 {
  color: #3396d3 !important;
}

.makeStyles-active-29 {
  color: #3396d3 !important;
}

.makeStyles-active-29 .makeStyles-icon-27 {
  color: #3396d3 !important;
}

.makeStyles-active-34 {
  color: #3396d3 !important;
}

.makeStyles-active-34 .makeStyles-icon-32 {
  color: #3396d3 !important;
}

.react-confirm-alert-body {
  z-index: 200 !important;
}

.react-confirm-alert-body>h1 {
  font-size: 20px !important;
}

.react-confirm-alert-button-group {
  justify-content: center !important;
}

/* thead {
  background-color: rgb(255, 166, 0) !important;
} */
/* thead th {
  color: white !important;
} */
.Master__title {
  font-size: 30px;
  font-weight: 600;
}

.csvButton {
  background-color: #a0cb3a !important;
  color: white !important;
}

/* select {
  /* width: 400px !important;
  height: 30px !important;
  border: 1px solid #959595 !important;
  border-radius: 4px !important;
  background-color: white !important;
} */
.Master__title {
  margin-top: 50px;
  font-weight: 600;
}

.MuiPaper-elevation1 {
  /* box-shadow: none !important; */
}

.MuiInput-underline::after {
  border-bottom: 2px solid #3396d3 !important;
}

.rc-anchor {
  width: 298px !important;
  height: 41px !important;
}

.flexContainer>* {
  margin: 8px 0 !important;
  margin-right: 15px !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-animation-delay: 1s;
  /* Safari support - any positive time runs instantly */
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

@-webkit-keyframes autofill {

  0%,
  100% {
    color: #666;
    background: transparent;
  }
}

.MuiPaginationItem-page {
  border-radius: 4px !important;
}

.MuiPaginationItem-page.Mui-selected {
  background-color: #f9ae15 !important;
  color: white !important;
}

/* .sort {
  display: flex !important;
  align-items: center !important;
} */
/* Landscape phones and down */

@media (max-width: 480px) {
  .logo__right {
    display: none;
  }

  .cards__grid {
    margin: auto !important;
    width: 100% !important;
    place-items: center !important;
  }

  .threeButtons {
    flex-direction: column !important;
  }

  .threeButtons>button {
    width: 100% !important;
    margin: auto !important;
    margin-bottom: 10px !important;
    height: 40px;
  }

  .user_search {
    width: 100% !important;
    height: 37px !important;
    margin: 0 !important;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
  }

  .user_search_form {
    width: 100% !important;
    height: 37px !important;
    margin: 0 !important;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
  }

  .dashboard__heading {
    margin: 20px 0 0 15px !important;
  }

  .fullWidth {
    width: 100% !important;
    padding: 0 !important;
  }

  .flexContainer>* {
    margin: 8px 0 !important;
    margin-right: 0 !important;
  }

  .tapWater {
    text-align: center !important;
  }
}

header {
  box-shadow: 0px 4px 8px #6769781a !important;
}

input.tableHeadSearch,
select.tableHeadSearch {
  padding: 12px 23px;
  /* border: 1px solid; */
  border: 1px solid #0000001a;
  border-radius: 5px;
  background-color: ffffff;
  color: #09090999;
  width: 170px;
  height: 40px;
}

.MuiBox-root-40 {
  margin-bottom: 10px;
}

.MuiTableCell-root {
  font: normal normal normal 14px/17px "Avenir W01", Avenir, Arial, sans-serif !important;
  letter-spacing: 0.56px;
  color: #090909;
  text-align: left;
}

/* .MuiBox-root-40,
.MuiBox-root-41,
.MuiBox-root-42,
.MuiBox-root-43,
.MuiBox-root-44,
.MuiBox-root-37,
.MuiBox-root-75,
.MuiBox-root-86 {
  font: normal normal normal 14px/17px "Avenir W01", Avenir, Arial, sans-serif;
  letter-spacing: 0.56px;
  color: #090909;
  text-align: left;
} */
.MuiOutlinedInput-input {
  /* width: 100%; */
  border: 1px #0000001a;
  padding: 13.5px 14px !important;
}

button {
  font: normal normal normal 16px/19px "Avenir W01", Avenir, Arial, sans-serif;
}


/* Slider */
.slider {
  width: 280px;
  margin: 20px 40px;
  overflow: hidden;

}

.slide-items {
  display: flex;
  /* width: 3300px; */
  flex-wrap: wrap;
  transition: all 300ms;
}

.item {
  /* flex-basis: 300px; */
  /* height: 350px; */
  vertical-align: top;
  font-size: 15px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  /* color: #fff; */
  /* text-transform: uppercase; */
  /* background-color: white; */
}

/* .item:first-child {
  background: #548687;
}
.item:nth-child(2) {
  background: #8FBC94;
}
.item:nth-child(3) {
  background: #56445D;
} */
.indicators {
  display: flex;
  list-style: none;
  /* margin-top: 10px; */
  justify-content: center;
}

.indicators li {
  height: 10px;
  width: 10px;
  /* background: #D6002A; */
  border-radius: 50%;
  margin-right: 5px;
  cursor: pointer;
  /* opacity: .8; */
  transition: all 300ms;
}

.indicators li:hover {
  opacity: 1;
}

.indicators li.active {
  width: 24px;
  background: #D6002A;
  border-radius: 30%;
}

.MuiChip-root {
  margin-top: 7px !important;
  background-color: transparent !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}