@import url(https://fast.fonts.net/t/1.css?apiType=css&projectid=a594d1e3-da5f-415d-ae9f-971e9797b9d5);
body,
html {
  font-family: "Avenir W01", Avenir, Arial, sans-serif;
  font-size: 90.5%;
  /* 62.5% of 16px = 10px */
}

body {
  background-color: #f5f5f563 !important;
}

.layoutPage {
  background-color: #f5f5f563;
}

.MuiInputBase-input {
  height: 0.5rem;
}

.MuiSelect-select.MuiSelect-select {
  padding-right: 24px;
  background-color: transparent !important;
}

.textselect {
  height: auto !important;
}

button {
  text-transform: unset !important;
}

.MuiFormControl-fullWidth {
  width: 100%;
  background-color: #fff;
}

.MuiDivider-root {
  background-color: transparent !important;
}

.MuiList-root {
  margin-top: 0px !important;
}

.MuiButton-root {
  background-color: #3396d3;
}

.hero-banner {
  z-index: 0;
}

.makeStyles-desktopDrawer-11 {
  width: 255px !important;
  border-right: none !important;
}

.MuiDropzoneArea-icon {
  color: 'red';
  height: '40px';

}

/* .pd-user-create {
  padding: 0% !important;
} */

.MuiOutlinedInput-marginDense {
  padding: 0px !important;
}

.MuiButton-root {
  background-color: #3396d3;
}

.hero-banner {
  z-index: 0;
}

.inputRounded .MuiOutlinedInput-root {
  /* border-radius: 10px; */
  padding-top: 4px;
  height: 35px;
  min-height: 35px;
  border-radius: 2px !important;
}

.MuiInputAdornment-root {
  /* border-radius: 10px; */
  height: 35px;
  min-height: 35px;
}

.MuiInput-root {
  /* border-radius: 10px; */
  height: 35px;
  min-height: 35px;
}

.MuiAutocomplete-root {
  /* border-radius: 10px; */
  /* height: 35px; */
  min-height: 35px;
}

.MuiFormLabel-root {
  color: "red"
}

.MuiOutlinedInput-root {
  /* border-radius: 10px; */
  padding-top: 4px;
  background-color: #ffffff;
  min-height: 35px;
}

/* .MuiList-root .MuiList-padding .css-h4y409-MuiList-root {
  z-index: 9999 !important;
} */
.autoComplete .MuiAutocomplete-inputRoot {
  /* border-radius: 10px; */
  /* background-color: #3396d3; */
  padding-top: 4px;
  min-height: 35px;
}

.autoComplete .MuiAutocomplete-input {
  /* width: 10px; */
  padding-top: 4px;
  /* border-radius: 10px; */
  height: 12px;
  /* background-color: #a0cb3a; */
}

.MuiAutocomplete-popupIndicator .MuiAutocomplete-popupIndicatorOpen .MuiAutocomplete-popper {
  z-index: 1100 !important;
}

.MuiAutocomplete-popper {
  z-index: 1100 !important;
}

.MuiAutocomplete-popper {
  z-index: 1100 !important;
}

/* .row > th {
  width: 10px !important;
  padding: 0px 26px !important;
} */
.margin-scale {
  margin-top: 25px;
}

.image-zoom {
  background-color: #6660 !important;
  -webkit-transform: scale(0.7);
          transform: scale(0.7);
}

.image-zoom:hover {
  -webkit-transform: scale(3.5);
          transform: scale(3.5);
}

.progessBar {
  display: grid;
  place-items: center;
  height: 100vh;
}

.progessBar>.MuiCircularProgress-indeterminate {
  margin-bottom: 200px;
}

.commonLoader {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.zoom {
  transition: -webkit-transform 0.2s !important;
  transition: transform 0.2s !important;
  transition: transform 0.2s, -webkit-transform 0.2s !important;
  /* Animation */
  width: 200px !important;
  height: 200px !important;
  border: none !important;
}

/* .chartjs-render-monitor {
  width: 40vw;
  margin: auto;
  height: 30vh;
  height: 295px !important;
} */
#root {
  background-color: white !important;
}

.makeStyles-active-28 {
  color: #3396d3;
}

.makeStyles-active-28 .makeStyles-icon-26 {
  color: #3396d3 !important;
}

.makeStyles-active-29 {
  color: #3396d3 !important;
}

.makeStyles-active-29 .makeStyles-icon-27 {
  color: #3396d3 !important;
}

.makeStyles-active-34 {
  color: #3396d3 !important;
}

.makeStyles-active-34 .makeStyles-icon-32 {
  color: #3396d3 !important;
}

.react-confirm-alert-body {
  z-index: 200 !important;
}

.react-confirm-alert-body>h1 {
  font-size: 20px !important;
}

.react-confirm-alert-button-group {
  justify-content: center !important;
}

/* thead {
  background-color: rgb(255, 166, 0) !important;
} */
/* thead th {
  color: white !important;
} */
.Master__title {
  font-size: 30px;
  font-weight: 600;
}

.csvButton {
  background-color: #a0cb3a !important;
  color: white !important;
}

/* select {
  /* width: 400px !important;
  height: 30px !important;
  border: 1px solid #959595 !important;
  border-radius: 4px !important;
  background-color: white !important;
} */
.Master__title {
  margin-top: 50px;
  font-weight: 600;
}

.MuiPaper-elevation1 {
  /* box-shadow: none !important; */
}

.MuiInput-underline::after {
  border-bottom: 2px solid #3396d3 !important;
}

.rc-anchor {
  width: 298px !important;
  height: 41px !important;
}

.flexContainer>* {
  margin: 8px 0 !important;
  margin-right: 15px !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-animation-delay: 1s;
  /* Safari support - any positive time runs instantly */
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

@-webkit-keyframes autofill {

  0%,
  100% {
    color: #666;
    background: transparent;
  }
}

.MuiPaginationItem-page {
  border-radius: 4px !important;
}

.MuiPaginationItem-page.Mui-selected {
  background-color: #f9ae15 !important;
  color: white !important;
}

/* .sort {
  display: flex !important;
  align-items: center !important;
} */
/* Landscape phones and down */

@media (max-width: 480px) {
  .logo__right {
    display: none;
  }

  .cards__grid {
    margin: auto !important;
    width: 100% !important;
    place-items: center !important;
  }

  .threeButtons {
    flex-direction: column !important;
  }

  .threeButtons>button {
    width: 100% !important;
    margin: auto !important;
    margin-bottom: 10px !important;
    height: 40px;
  }

  .user_search {
    width: 100% !important;
    height: 37px !important;
    margin: 0 !important;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
  }

  .user_search_form {
    width: 100% !important;
    height: 37px !important;
    margin: 0 !important;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
  }

  .dashboard__heading {
    margin: 20px 0 0 15px !important;
  }

  .fullWidth {
    width: 100% !important;
    padding: 0 !important;
  }

  .flexContainer>* {
    margin: 8px 0 !important;
    margin-right: 0 !important;
  }

  .tapWater {
    text-align: center !important;
  }
}

header {
  box-shadow: 0px 4px 8px #6769781a !important;
}

input.tableHeadSearch,
select.tableHeadSearch {
  padding: 12px 23px;
  /* border: 1px solid; */
  border: 1px solid #0000001a;
  border-radius: 5px;
  background-color: ffffff;
  color: #09090999;
  width: 170px;
  height: 40px;
}

.MuiBox-root-40 {
  margin-bottom: 10px;
}

.MuiTableCell-root {
  font: normal normal normal 14px/17px "Avenir W01", Avenir, Arial, sans-serif !important;
  letter-spacing: 0.56px;
  color: #090909;
  text-align: left;
}

/* .MuiBox-root-40,
.MuiBox-root-41,
.MuiBox-root-42,
.MuiBox-root-43,
.MuiBox-root-44,
.MuiBox-root-37,
.MuiBox-root-75,
.MuiBox-root-86 {
  font: normal normal normal 14px/17px "Avenir W01", Avenir, Arial, sans-serif;
  letter-spacing: 0.56px;
  color: #090909;
  text-align: left;
} */
.MuiOutlinedInput-input {
  /* width: 100%; */
  border: 1px #0000001a;
  padding: 13.5px 14px !important;
}

button {
  font: normal normal normal 16px/19px "Avenir W01", Avenir, Arial, sans-serif;
}


/* Slider */
.slider {
  width: 280px;
  margin: 20px 40px;
  overflow: hidden;

}

.slide-items {
  display: flex;
  /* width: 3300px; */
  flex-wrap: wrap;
  transition: all 300ms;
}

.item {
  /* flex-basis: 300px; */
  /* height: 350px; */
  vertical-align: top;
  font-size: 15px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  /* color: #fff; */
  /* text-transform: uppercase; */
  /* background-color: white; */
}

/* .item:first-child {
  background: #548687;
}
.item:nth-child(2) {
  background: #8FBC94;
}
.item:nth-child(3) {
  background: #56445D;
} */
.indicators {
  display: flex;
  list-style: none;
  /* margin-top: 10px; */
  justify-content: center;
}

.indicators li {
  height: 10px;
  width: 10px;
  /* background: #D6002A; */
  border-radius: 50%;
  margin-right: 5px;
  cursor: pointer;
  /* opacity: .8; */
  transition: all 300ms;
}

.indicators li:hover {
  opacity: 1;
}

.indicators li.active {
  width: 24px;
  background: #D6002A;
  border-radius: 30%;
}

.MuiChip-root {
  margin-top: 7px !important;
  background-color: transparent !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* .MuiToolbar-gutters {
  background-color: #3396d3 !important;
}
logo__right
.MuiTablePagination-toolbar {
  background-color: #fff !important;
} */
/* MuiToolbar-root MuiToolbar-regular MuiToolbar-gutters */
/* MuiToolbar-root MuiToolbar-regular MuiTablePagination-toolbar MuiToolbar-gutters */

.hide {
  -webkit-transform: translateY(-140px);
          transform: translateY(-140px);
}
.logo__right {
  position: fixed;
  right: 10px;
  margin-right: 10px;
  top: 30px;
  display: inline;
  /* box-shadow: 0px 3px 6px #0000002E; */
}
/* Landscape phones and down */
@media (max-width: 480px) {
  .logo__right {
    display: none !important;
  }
  .topbar__para {
    font-size: 12px !important;
  }
  .topbar__title {
    font-size: 16px !important;
  }
}
.makeStyles-navbar-18 {
  background-color: white !important;
}
.MuiTableCell-sizeSmall:last-child {
  color: #b10932;
}
.MuiTableCell-head {
  font-weight: bold;
  color: #b10932;
}
.MuiPaginationItem-page.Mui-selected {
  background-color: #b10932 !important;
}
/* .MuiGrid-grid-xs-12{
  min-width:100%;
} */

.viewWrapper {
  /* display:flex;
  justify-content: space-evenly; */
  /* flex-wrap:wrap; */
}
.viewWrapper li {
  list-style-type: none;
}
.left {
  background-color: white !important;
  color: #b10932 !important;
}
.right {
  color: white;
  background-color: #b10932 !important;
}
.main {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-grow: row-wrap;
}
.top {
  width: 50%;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}
.MuiToolbar-regular {
  background-color: white !important;
}

.makeStyles-title-40 {
  color: #b10932 !important;
}

.makeStyles-icon-39 {
  color: #b10932 !important;
}
.makeStyles-icon-34 p {
  color: #b10932 !important;
}
.makeStyles-navbar-12 {
  background-color: white !important;
}
.makeStyles-title-36 {
  color: #b10932 !important;
}
.makeStyles-icon-35 {
  color: #b10932 !important;
}
/* .MuiButton-label p,
svg,
span {
  color: #b10932 !important;
} */
.makeStyles-navbar-18 {
  background-color: white !important;
}
.makeStyles-navbar-13 {
  background-color: white !important;
}
/* .MuiButton-label p,svg{
  color:#B10932 !important; 
} */

.top2 {
  padding-top: 20px;
}

/* .MuiToolbar-gutters {
  background-color: #3396d3 !important;
}
.MuiTablePagination-toolbar {
  background-color: #fff !important;
} */
/* MuiToolbar-root MuiToolbar-regular MuiToolbar-gutters */
/* MuiToolbar-root MuiToolbar-regular MuiTablePagination-toolbar MuiToolbar-gutters */

.topBarWrapper,
.topBarWrapper1 {
  height: 3.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  /* background-color: orange; */
  background-color: #f2e0e5;
  /* top: 10px; */
  /* margin-top: 13px; */
  margin: 12px 12px 25px 12px;
  border-radius: 5px;
}
.tobBarBackBtn svg {
  color: #b10932 !important;
}
.topBarWrapper1 {
  justify-content: flex-start;
}
.topBarWrapper h2 {
  font: normal normal normal 16px/20px "Avenir W01", Avenir, Arial, sans-serif;
  color: #b10932;
  opacity: 1;
}

.tobBarBackBtn {
  margin-top: 0.3rem;
  cursor: pointer;
  padding-right: 0.6rem;
}

.topBarWrapper1 a {
  margin-top: 5px;
  padding-right: 1rem;
  color: var(--colorPrimary);
}

.topBarRightBtnsWrapper {
  display: flex;
}

.topBarRightBtnsWrapper .csv {
  margin-right: 0.5rem;
  cursor: pointer;
  background-color: var(--colorPrimary);
  border-radius: 0.3rem;
  border: none;
  outline: none;
  padding: 0.5rem;
  color: #f1f1f1;
  transition: -webkit-transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
  font-size: 0.9rem;
  font-weight: 700;
  min-width: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.topBarRightBtnsWrapper .csv:active {
  -webkit-transform: scale(0.96);
          transform: scale(0.96);
}

.topBarInner {
  display: flex;
  align-items: center;
}

.topBarInner > a {
  margin-right: 1rem;
  margin-top: 0.5em;
}

.csvBtn {
  margin-right: 0.5rem;
  outline: none;
  border-radius: 0.3rem;
  /* color: var(--colorPrimary);
  border: 2px solid var(--colorPrimary); */
  color: #b10932;
  border: 2px solid #b10932;
  background-color: white;
  /* margin-top: 10px; */

  cursor: pointer;
  min-width: 8rem;
  font-size: 0.9rem;
  font-weight: 700;
  padding: 0.5rem;
}
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
  .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  padding-right: 65px;
  background-color: #ffffff;
}
.MuiInputBase-input:invalid {
  background-color: #ffffff !important;
}
.MuiNativeSelect-select .MuiNativeSelect-select {
  font-size: 7px;
}
/* .MuiCard-root:not(:first-child) {
  color: #b10932;
} */
.left {
  float: left;
  background-color: aquamarine;
  width: 150px;
}
.right {
  float: right;
  background-color: aqua;
  width: 150px;
}
.mainn {
  width: 300px;
}
.MuiInput-input {
  font-size: 10px;
}
/* import {
  Button,
  ListItem,
  makeStyles
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { RiArrowDropDownLine } from 'react-icons/ri';
import Fade from 'react-reveal/Fade';
import { NavLink as RouterLink } from 'react-router-dom';
import './
';
import styles from './NavItem.module.css';
// import { loadData } from 'src/utils/sessionStorage'; */

/* const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  }, */
/* button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%',
  },
  icon: {
    marginRight: theme.spacing(1),
    color: 'white',
  },
  title: {
    marginRight: 'auto',
    color: 'white',
    fontSize: '16px',
  },
  active: {
    color: theme.palette.primary.main,

    '& $title': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& $icon': {
      color: 'white',
    },
  },
  formControl: {
    minWidth: 100,
  },
  drop: {
    display: 'flex',
    alignItems: 'center',
  },
}));
const NavItem = ({ className, href, icon: Icon, title, ...rest }) => {
  const classes = useStyles();
  const [user, setUser] = React.useState(false);
  const userOpen = () => {
    setUser(!user);
  };

  return (
    <>
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        {...rest}
      >
        <Button
          activeClassName={classes.active}
          className={classes.button}
          component={RouterLink}
          to={href}
        >
          {Icon && <Icon className={classes.icon} size="20" />}
          <span className={classes.title}>
            {title === 'Users' || title === 'Master' || title === 'Forms' ? (
              <div>
                <div className={styles.flex} onClick={userOpen}>
                  <p>{title}</p>
                  <RiArrowDropDownLine
                    style={{ color: '#B10932' }}
                    className={styles.dropdown}
                  />
                </div>
              </div>
            ) : (
              title
            )}
          </span>
        </Button>
      </ListItem>
      {user && title === 'Users' && (
        <div>
          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={RouterLink}
            to="/app/usercreate"
          >
            <Fade>
              <div className={styles.dropdownBox + ' ' + classes.drop}>
                <Icon className={classes.icon} size="20" />
                <p className={classes.title}>Create User</p>
              </div>
            </Fade>
          </Button>

          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={RouterLink}
            to="/app/userlist"
          >
            <Fade>
              <div className={styles.dropdownBox + ' ' + classes.drop}>
                <Icon className={classes.icon} size="20" />
                <p className={classes.title}>User List</p>
              </div>
            </Fade>
          </Button>
        </div>
      )}
      {user && title === 'Master' && (
        <div>
          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={RouterLink}
            to="/app/state/list"
          >
            <Fade>
              <div className={styles.dropdownBox + ' ' + classes.drop}>
                <p className={classes.title}>State</p>
              </div>
            </Fade>
          </Button>
          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={RouterLink}
            to="/app/district/list"
          >
            <Fade>
              <div className={styles.dropdownBox + ' ' + classes.drop}>
                <p className={classes.title}>District</p>
              </div>
            </Fade>
          </Button>
          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={RouterLink}
            to="/app/tea_estate/list"
          >
            <Fade>
              <div className={styles.dropdownBox + ' ' + classes.drop}>
                <p className={classes.title}>Tea estate</p>
              </div>
            </Fade>
          </Button>
          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={RouterLink}
            to="/app/division/list"
          >
            <Fade>
              <div className={styles.dropdownBox + ' ' + classes.drop}>
                <p className={classes.title}>Division</p>
              </div>
            </Fade>
          </Button>
        </div>
      )}
    </>
  );
};


 */

img.top1 {
  width: 137px;
  height: 50px;
}
.top2 {
  width: 86px;
  height: 45px;
  padding-top: 5px !important;
}
/* logo */
.makeStyles-title-21 {
  color: #676767 !important;
}

/*  */
.MuiButton-containedPrimary {
  color: #fff;
  background-color: #19191a;
}
.makeStyles-title-27,
.makeStyles-title-21,
.makeStyles-title-26,
.makeStyles-item-23,
.MuiFormControl-root.MuiFormControl-fullWidth {
  border-radius: 5px;
  font: normal normal 400 16px/22px "Avenir W01", Avenir, Arial, sans-serif;
}

.MuiButton-label p,
svg {
  /* color: #676767 !important; */
}

.MuiFormControl-root.MuiFormControl-fullWidth > label {
  margin-left: 5px;

  letter-spacing: 0.64px;

  font: normal normal 400 16px/22px "Avenir W01", Avenir, Arial, sans-serif;
}
/* .MuiGrid-spacing-xs-3 > .MuiGrid-item,
.MuiBox-root-19 {
  background-color: #f6f6f6;
} */
.MuiCard-root {
  margin: 10px;
  border-radius: 5px;
  background-color: #f6f6f6 !important;
  border: 1px solid #f6f6f6;
}

